@use "src/shared/styles/variables" as *;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  -webkit-tap-highlight-color: transparent;
}

*::-webkit-scrollbar {
  display: none;
}

html {
  background: var(--orange);
}

body {
  overflow: hidden;
  color: var(--secondary-900);
  scroll-behavior: smooth;
}

button {
  background: none;
  padding: none;
  outline: none;
  border: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

strong {
  font-weight: 600;
}

.toast-container {
  bottom: 40px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.disabled {
  color: var(--disabled-text) !important;
  background: var(--disabled-background) !important;
}

.disabled:hover {
  background: var(--disabled-background) !important;
}

.disabled-text {
  color: var(--disabled-text) !important;
}
