@use "src/shared/styles/variables" as *;

.container {
  text-align: left;
}

.content-container {
  height: auto;
  min-height: 45vh;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease;
}

.form-content {
  position: absolute;
  width: 100%;
  pointer-events: none;
  text-align: left;
  top: 0;
  left: 0;
  display: none;
}

.activeSlide {
  pointer-events: all;
  display: block;
}

@media screen and (min-width: $bp-sm) {
  .container {
    text-align: center;
  }

  .content-container {
    min-height: 200px;
  }
}

.navigate * {
  font-size: var(--p-font-size);
  text-align: center;
}
