@use "src/shared/styles/variables" as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
}

.tabs-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--secondary-100);
  margin-bottom: var(--v6);
}

.control-button {
  background: white;
  border: 1px solid var(--secondary-200);
  padding: 10px;
  border-radius: $border-radius;
  margin: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--secondary-900);
}

.active-control {
  color: var(--primary-700);
  border: 1px solid transparent;
  background: var(--primary-100);
}

.grid {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
