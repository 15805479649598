@use "src/shared/styles/variables" as *;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  width: 100%;
}

.container button {
  margin: var(--v1);
}

@media screen and (min-width: $bp-sm) {
  .container {
    flex-direction: row;
  }

  .container button {
    margin: var(--v3);
  }

  .container button:first-child {
    margin-left: 0px;
  }

  .container button:last-child {
    margin-right: 0px;
  }
}

.noBottomPadding button {
  margin-bottom: 0px !important;
}
