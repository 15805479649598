@use "src/shared/styles/variables" as *;

.icon {
  position: relative;
  background: var(--secondary-100);
  color: var(--secondary-600);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 18px;
  margin-right: var(--v1);
  cursor: pointer;
}

.icon.selected {
  background: var(--primary-50);
  color: var(--orange);
}
.badge {
  background: var(--orange);
  width: 20px;
  height: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 10px;
}

.notifications-container {
  width: 500px;
  background: white;
  box-shadow: $box-shadow;
  position: fixed;
  right: 0;
  top: 80px;
  overflow: scroll;
  z-index: 102;
  border-radius: 0 0 $border-radius $border-radius;
}
