.tooltip-wrapper {
  display: inline-block;
  width: 100%;
}

.tooltip-content {
  visibility: hidden;
  background-color: var(--secondary-50);
  color: var(--secondary-1000);
  text-align: left;
  padding: var(--v2);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + var(--v1));
  left: 0;
  z-index: 10;
  width: 100%;
  font-size: 0.875rem;
  white-space: normal;
}

.tooltip-wrapper:hover .tooltip-content {
  visibility: visible;
}
