@use "src/shared/styles/variables" as *;

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 105;
  background: white;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  padding: var(--v4);
  max-width: 600px;
  pointer-events: none;
  opacity: 0;
  transition: all 200ms ease;
  padding-bottom: 0px;
}

.container.visible {
  pointer-events: all;
  opacity: 1;
}

.container h3 span {
  font-weight: 100;
  font-style: italic;
  display: block;
}

.container p {
  font-size: var(--label-font-size);
}

.container ul li {
  margin: var(--v2);
  font-size: var(--label-font-size);
}

.warning {
  background: var(--warning-light);
  border-left: 5px solid var(--warning-dark);
  padding: var(--v2);
  margin: var(--v3) 0;
}
