.container {
  position: relative;
  padding: 30px 20px;
  margin: 20px;
  width: 20vw;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  border-radius: 15px;
}

.description {
  margin: 20px 0;
  line-height: 2;
  font-size: 14px;
  font-weight: 500;
  height: 100px;
}

.highlight {
  color: var(--orange);
  font-weight: 600;
}

.prices {
  border-top: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
  padding: 10px 0;
  margin: 20px 0;
}

.prices p {
  margin: 10px 0;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.active-plan {
  background: var(--secondary-900);
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-100%);
  padding: 10px 20px;
  border-radius: 15px 15px 0 0;
}
