@use "src/shared/styles/variables" as *;

// Break Points
$breakpoints: (
  xxs: $bp-xxs,
  // Micro (XXS) - Small phones
  xs: $bp-xs,
  // Small Phones (XS) - Standard mobile
  sm: $bp-sm,
  // Large Phones (SM) - Big-screen phones
  smd: $bp-smd,
  // Foldables (SMD) - Foldable devices
  md: $bp-md,
  // Tablets (MD) - iPad Mini, budget Android tablets
  lgt: $bp-lgt,
  // Large Tablets (LGT) - iPad Air/Pro, Surface Go
  lg: $bp-lg,
  // Small Laptops (LG) - 13" MacBook Air, small Chromebooks
  xl: $bp-xl,
  // Standard Laptops (XL) - Most work laptops, 1080p monitors
  xxl: $bp-xxl,
  // Large Desktops (XXL) - Ultra-wide screens
  xxxl: $bp-xxxl // 4K Screens (XXXL) - High-res displays, TV monitors,,,,,,
);

// 📌 Base Flex Classes (Always Applied)
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

// 📌 Small Screen Override (Column Reverse)
@media (max-width: $bp-sm) {
  .flex {
    flex-direction: column-reverse;
  }
}

.flex-between {
  justify-content: space-between;
}
.flex-center {
  justify-content: center;
  align-items: center;
}
.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}

// 📌 Alignment Utilities
.flex-start {
  justify-content: flex-start;
}
.flex-end {
  justify-content: flex-end;
}
.flex-align-start {
  align-items: flex-start;
}
.flex-align-end {
  align-items: flex-end;
}
.flex-align-center {
  align-items: center;
}
.flex-align-baseline {
  align-items: baseline;
}
.flex-align-stretch {
  align-items: stretch;
}

// 📌 Justify Content Utilities
.flex-justify-start {
  justify-content: flex-start;
}
.flex-justify-end {
  justify-content: flex-end;
}
.flex-justify-center {
  justify-content: center;
}
.flex-justify-space-between {
  justify-content: space-between;
}
.flex-justify-space-around {
  justify-content: space-around;
}
.flex-justify-space-evenly {
  justify-content: space-evenly;
}

// 📌 Wrap Utilities
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

// 📌 Mixin for Responsive Flex Classes (Suffix-First)
@mixin responsive-flex($bp, $suffix) {
  @media (min-width: $bp) {
    .#{$suffix}-flex {
      display: flex;
    }
    .#{$suffix}-flex-between {
      justify-content: space-between;
    }
    .#{$suffix}-flex-center {
      justify-content: center;
      align-items: center;
    }
    .#{$suffix}-flex-column {
      flex-direction: column;
    }
    .#{$suffix}-flex-row {
      flex-direction: row;
    }
    .#{$suffix}-flex-row-reverse {
      flex-direction: row-reverse;
    }
    .#{$suffix}-flex-column-reverse {
      flex-direction: column-reverse;
    }
    .#{$suffix}-flex-wrap {
      flex-wrap: wrap;
    }
    .#{$suffix}-flex-nowrap {
      flex-wrap: nowrap;
    }
    .#{$suffix}-flex-wrap-reverse {
      flex-wrap: wrap-reverse;
    }

    .#{$suffix}-flex-justify-start {
      justify-content: flex-start;
    }
    .#{$suffix}-flex-justify-end {
      justify-content: flex-end;
    }
    .#{$suffix}-flex-justify-center {
      justify-content: center;
    }
    .#{$suffix}-flex-justify-space-between {
      justify-content: space-between;
    }
    .#{$suffix}-flex-justify-space-around {
      justify-content: space-around;
    }
    .#{$suffix}-flex-justify-space-evenly {
      justify-content: space-evenly;
    }

    .#{$suffix}-flex-align-start {
      align-items: flex-start;
    }
    .#{$suffix}-flex-align-end {
      align-items: flex-end;
    }
    .#{$suffix}-flex-align-center {
      align-items: center;
    }
    .#{$suffix}-flex-align-baseline {
      align-items: baseline;
    }
    .#{$suffix}-flex-align-stretch {
      align-items: stretch;
    }
    .#{$suffix}-flex-align-space-between {
      align-items: space-between;
    }
    .#{$suffix}-flex-align-space-around {
      align-items: space-around;
    }
    .#{$suffix}-flex-align-space-evenly {
      align-items: space-evenly;
    }
  }
}

// 📌 Generate Responsive Variants for All Breakpoints
@each $suffix, $bp in $breakpoints {
  @include responsive-flex($bp, $suffix);
}
