@use "src/shared/styles/variables" as *;

.slide-container {
  height: calc(60vh);
  overflow: visible;
}

.show {
  top: 40vh !important;
}

.profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid var(--secondary-900);
  overflow: hidden;
  margin: var(--v3) auto;
}
