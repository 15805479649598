@use "src/shared/styles/variables" as *;

.boxes {
  flex: 1;
}

.block {
  border-radius: $border-radius;
  padding: var(--v2);
  height: 100%;
  margin: var(--v2);
}

@media screen and (min-width: $bp-sm) {
  .block {
    margin: 0 var(--v2);
  }
}

.shifter {
  background: var(--primary-50);
  border: 2px solid var(--orange);
}

.costs {
  border: 2px solid var(--secondary-900);
}

.footer {
  border-top: 1px solid var(--border);
  margin-top: var(--v3);
}

.profile-picture {
  width: 80px;
  height: 80px;
  border: 1px solid var(--secondary-900);
  border-radius: 50%;
  overflow: hidden;
}

.cost {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: var(--v1) 0;
}

.totals {
  border-top: 1px solid var(--border);
  margin-top: var(--v3);
}

.icon {
  width: 30px;
}
