@use "src/shared/styles/variables" as *;

.grid {
  margin-top: var(--v6);
}

.element {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  padding: var(--v1) 0;
}

.heading {
  border-bottom: 1px solid var(--orange);
  display: inline-block;
}
.title {
  border-right: 1px solid var(--border);
  color: var(--secondary-700);
}

.body {
  margin-top: var(--v2);
}

.title,
.body {
  grid-column: 1 / 1;
}

@media screen and (min-width: $bp-md) {
  .element {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
  }

  .title {
    grid-column: 1 / 1;
  }

  .body {
    margin-top: 0;
    grid-column: 2 / 5;
    padding: 0 var(--v2);
  }
}
