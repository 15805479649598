.button {
  border-radius: 8px;
  color: white;
  background: var(--orange-gradient);
  padding: max(var(--v2), 16px) var(--v4);
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  transition: all 200ms ease;
  position: relative;
  font-size: var(--p-font-size);
  min-width: 200px;
}

.content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content.left {
  transform: translateX(-10px);
}

.content.right {
  transform: translateX(10px);
  flex-direction: row-reverse;
}

.content.left .loading-spinner {
  transform: translateX(10px) !important;
}
.content.right .loading-spinner {
  transform: translateX(-10px) !important;
}

.primary {
  background: var(--orange-gradient);
}

.secondary {
  background: var(--secondary-1000);
  color: white;
}

.primary-transparent {
  background: white;
  color: var(--secondary-1000);
}

.primary-transparent svg {
  color: var(--orange);
}

.secondary-transparent {
  background: white;
  color: var(--secondary-1000);
}

.primary-outline {
  background: white;
  color: var(--orange);
  border: 1px solid var(--orange);
}

.secondary-outline {
  background: white;
  color: var(--secondary-1000);
  border: 1px solid var(--secondary-1000);
}

.secondary-outline:hover {
  background: var(--secondary-100);
}

.danger {
  background: var(--error);
  color: white;
}

.danger-outline {
  background: white;
  color: var(--error);
  border: 1px solid var(--error);
}

.danger-transparent {
  background: white;
  color: var(--error);
  text-decoration: underline;
}

.button h6 {
  font-weight: 600;
  font-size: 16px !important;
}

.fw {
  width: 100%;
}

.flex {
  flex: 1;
}

.icon {
  margin: 0 10px;
}

.disabled {
  background: var(--disabled-background) !important;
  color: var(--disabled-text) !important;
  cursor: auto !important;
}

.tooltip-wrapper {
  display: inline-block;
  width: 100%;
}

.tooltip-content {
  visibility: hidden;
  background-color: var(--secondary-50);
  color: var(--secondary-1000);
  text-align: left;
  padding: var(--v2);
  border-radius: 6px;
  position: absolute;
  bottom: calc(100% + var(--v1));
  left: 0;
  z-index: 10;
  width: 100%;
  font-size: 0.875rem;
  white-space: normal;
}

.tooltip-wrapper:hover .tooltip-content {
  visibility: visible;
}
