@use "src/shared/styles/variables" as *;

@media screen and (min-width: $bp-md) {
  .modal {
    width: 85vw;
    max-width: 1200px;
  }

  .award-dropdown {
    height: 250px;
    margin: 0 auto;
    width: 50%;
  }
}

@media screen and (min-width: $bp-lg) {
  .modal {
    width: 75vw;
  }
}

.container {
  padding: var(--v3) 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.heading {
  padding: var(--v3);
}

.content {
  padding: var(--v3);
  padding-bottom: 0;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: $bp-md) {
  .content {
    padding-bottom: 150px;
    max-height: 70vh;
    overflow: scroll;
  }
}

@media screen and (min-width: $bp-md) {
  .short-content {
    width: 50%;
  }

  .medium-content {
    width: 70%;
  }
}
