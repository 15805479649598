@use "src/shared/styles/variables" as *;

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--v2);
}

.icon {
  margin: 0 var(--v2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.circle {
  background: var(--secondary-300);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: var(--v5);
  height: var(--v5);
  border-radius: 50%;
  margin-bottom: var(--v1);
}

.active .circle {
  background: var(--orange-gradient);
}
