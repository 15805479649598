@use "src/shared/styles/variables" as *;

.container {
  position: relative;
  align-items: center;
  justify-content: flex-start;
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.with-card {
  background: var(--secondary-50);
  height: auto;
}

.loading-container {
  min-height: calc(100vh - 160px);
}
.banner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--v2);
  min-height: 80px;
}

.chevron-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--v3);
  border-radius: 50%;
  height: (var(--v5));
  width: (var(--v5));
  background: white;
  font-size: var(--p-font-size);
}

.card {
  margin-inline: auto;
  width: 100%;
  margin: 0 var(--v3);
  padding-bottom: var(--v3);
  margin-bottom: var(--v6);
  flex: 1;
  display: flex;
  flex-direction: column;
}

.small {
  max-width: $bp-lgt;
}

.large {
  max-width: $bp-lg;
}

.with-card .card {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  background: white;
}

.content-container {
  padding: 0;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@media only screen and (min-width: $bp-sm) {
  .content-container {
    padding: 0 var(--v6);
  }
}
