@use "src/shared/styles/variables" as *;

.stepper-container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.progress-bar {
  position: absolute;
  width: calc(100% - var(--v3) * 2);
  height: 4px;
  border-radius: 25px;
  background-color: var(--secondary-100);
  bottom: auto;
  transform: translateY(-5px);
}

.bar {
  transition: all 0.3s ease;
  height: 100%;
  border-radius: 25px;
  background-color: var(--primary-500);
  width: 10%;
}

.steps-content-container {
  display: flex;
  // overflow-x: hidden;
  flex: 1;
  width: 100%;
}

.step-content {
  width: 100%;
  // overflow-x: hidden;
  flex-grow: 0;
  flex-shrink: 0;
}

.step-footer {
  width: 100%;
  padding: var(--v3) var(--v3) 0 var(--v3);
  pointer-events: none;
  background: white;
  border-top: 1px solid var(--border);
}

.step-footer button {
  pointer-events: all;
}
