.container {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.side-panel,
.center {
  height: 100%;

}

.side-panel {
  width: 30%;
}

.center {
  width: 70%;
}
