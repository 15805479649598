@use "src/shared/styles/variables" as *;

.Toastify__toast {
  font-family: "Plus Jakarta Sans" !important;
  line-height: 1.8 !important;
  font-size: var(--p-font-size);
  margin-right: var(--v3);
}

.Toastify__toast-container {
  width: 520px;
  max-width: 100%;
}

.Toastify__toast-icon {
  margin: 0 var(--v2) 0 var(--v1);
}
