@use "src/shared/styles/variables" as *;

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.icon.right {
  right: 15px;
}

.icon.left {
  left: -5px;
}

.hasIcon-left {
  padding-left: var(--v3);
}

.hasIcon-right {
  padding-right: var(--v3);
}
