.container {
  position: relative;
}

.tooltip-container {
  position: absolute;
  opacity: 0;
  transition: all 200ms ease;
  background: white;
  border-radius: 15px;
  border: 1px solid var(--border);
  padding: 10px;
  pointer-events: none;
  z-index: 50;
  box-shadow: var(--box-shadow);
  width: 400px;
}

.container p, .container h6 {
  text-align: left !important;

}

.container p {
  font-size: 14px ;
  font-weight: 600;
}

.container:hover .tooltip-container {
  opacity: 1;
}

.tooltip-container h6,
.tooltip-container p {
  margin: 10px 0;
}

.tooltip-container.top {
  bottom: 200%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-container.bottom {
  top: 200%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-container.left {
  top: 50%;
  right: 150%;
  transform: translateY(-50%);
}

.tooltip-container.right {
  top: 50%;
  left: 150%;
  transform: translateY(-50%);
}

.tooltip-arrow::before {
  content: "";
  position: absolute;
  border-style: solid;
}

.tooltip-arrow.top::before {
  top: 100%;
  left: 50%;
  border-width: 10px 10px 0 10px;
  border-color: var(--border) transparent transparent transparent;
  transform: translateX(-50%);
}

.tooltip-arrow.bottom::before {
  bottom: 100%;
  left: 50%;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent var(--border) transparent;
  transform: translateX(-50%);
}

.tooltip-arrow.left::before {
  left: 100%;
  top: 50%;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent var(--border);
  transform: translateY(-50%);
}

.tooltip-arrow.right::before {
  right: 100%;
  top: 50%;
  border-width: 10px 10px 10px 0;
  border-color: transparent var(--border) transparent transparent;
  transform: translateY(-50%);
}
