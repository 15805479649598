@use "src/shared/styles/variables" as *;

.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: var(--v3) 0;
  border-bottom: 1px solid var(--border);
}

.profile-container {
  border-right: 1px solid var(--border);
}

.profile-photo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 34px 0;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;

  background: var(--secondary-50);
}

.with-border {
  border: 2px dashed var(--secondary-400);
}

.add-container {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--secondary-200);
  border-radius: 50%;
}
