@use "src/shared/styles/variables" as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  width: 100%;
  background: white;
}

.illustration {
  max-width: 400px;
  width: 100%;
}
