@use "src/shared/styles/variables" as *;

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 105;
  background: white;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  padding: var(--v4);
  max-width: 600px;
  pointer-events: none;
  opacity: 0;
  transition: all 200ms ease;
  padding-bottom: 0px;
}

.container span {
  font-weight: 100;
  font-style: italic;
  display: block;
}

.container.visible {
  pointer-events: all;
  opacity: 1;
}

.warning {
  background: var(--warning-light);
  border-left: 5px solid var(--warning-dark);
}

.danger {
  background: var(--error-light);
  border-left: 5px solid var(--error-dark);
}

.info {
  background: var(--secondary-100);
  border-left: 5px solid var(--secondary-900);
}

/* Mobile */

.mobile-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 105;
}

.mobile-wrapper.mobile-visible {
  display: relative;
}

.modal-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  background: white;
  box-shadow: var(--box-shadow);
  padding: 20px;
  border-radius: 15px 15px 0 0;
  z-index: 115;
  padding-bottom: 40px;
  pointer-events: all;
}

.label-mobile {
  padding: 0 10px;
}

.mobile-buttons-container {
  flex-direction: column-reverse;
}

.mobile-buttons-container button {
  width: 100%;
  margin: 10px 0;
}
