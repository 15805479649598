@use "src/shared/styles/variables" as *;

/* Page */

.suspense {
  background: white;
}

.container {
  background: var(--secondary-100);
  padding: var(--v3) 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}

.content-wrapper {
  max-width: $bp-lgt;
  background: white;
  border-radius: $border-radius;
  width: 100%;
  min-height: 80vh;
  transition: all 200ms ease;
  box-shadow: $box-shadow;
  display: flex;
  flex-direction: column;
  padding-bottom: var(--v6);
}

.content {
  flex: 1;
}

.numbers {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
  transform: translateX(-20px);
}

.number {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: var(--secondary-100);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin-right: var(--v3);
}

.number p {
  font-size: var(--label-font-size);
}
.completed {
  background: var(--primary-700);
  color: white;
}

.active {
  background: var(--secondary-900);
  color: white;
}

.tab {
  height: 100%;
}

@media screen and (min-width: $bp-sm) {
  .content-wrapper {
    padding-bottom: 0;
  }
}
