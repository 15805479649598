@use "src/shared/styles/variables" as *;

.searchbox {
  box-shadow: $box-shadow;
  background: var(--primary-50);
  border: 1px solid var(--orange);
  width: 100%;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--v2);
}

.search-icon svg {
  color: var(--orange);
  font-size: 23px;
}

.filterbox {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  height: 50px;
  width: 50px;
  margin: var(--v2) 0;
  border: 1px solid var(--orange);
  background: white;
}

.filterbox svg {
  width: 20px;
  height: 20px;
}

.filter-addnotes {
  color: var(--secondary-600);
}

.filter-addnotes svg {
  margin-left: var(--v1);
}
