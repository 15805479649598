@use "src/shared/styles/variables" as *;

.container {
  position: fixed;
  bottom: 20px;
  left: 40px;
  width: 400px;
  background: white;
  z-index: 97;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--orange-gradient);
  color: white;
  padding: 10px 20px;
  border-radius: $border-radius $border-radius 0 0;
}

.heading h6 {
  font-size: 25px;
}

.taskList {
  overflow: scroll;
  flex: 1;
}

.taskContent.complete p {
  text-decoration: line-through;
  color: var(--secondary-500);
}

.task-icon {
  margin-right: var(--v2);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--secondary-100);
  color: var(--secondary-900);
  font-size: 14px;
}

.task-icon.complete {
  background: var(--success-50);
  color: var(--success-900);
}

.right-chevron {
  margin-left: auto;
  margin-right: var(--v1);
  font-size: 14px;
}

.chevron.chevron-open {
  transform: rotate(180deg);
}
