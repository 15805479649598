.container {
  padding: 20px;
}

/* Skeleton */
.skeleton-container {
  box-shadow: var(--box-shadow);
  border-radius: 15px;
  padding: 20px;
  margin: 10px 0;
}

.skeleton-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.skeleton-header-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sk-btn {
  margin-left: 5px;
}

.skeleton-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.no-shifts {
  text-align: center;
}

.no-shifts p {
  color: var(--secondary-900);
  font-weight: 600;
  font-size: 14px;
}
