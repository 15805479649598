@use "src/shared/styles/variables" as *;

.container {
  width: 225px;
  position: absolute;
  top: calc(100% + 10px);
  right: -var(--v1);
  background: white;
  box-shadow: var(--box-shadow);
  border-radius: $border-radius;
  z-index: 101;
  overflow: hidden;
  opacity: 0;
  transition: all 0.2s ease;
  visibility: hidden;
}

.container.open {
  opacity: 1;
  visibility: visible;
}

.row {
  padding: var(--v2) var(--v1);
  border-bottom: 1px solid var(--border);
}
.row p {
  font-size: var(--label-font-size);
}

.row:hover {
  background: var(--secondary-100);
}

/* Modal */

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
}

/* Load */
.loaded-templates {
  flex: 1;
  overflow: scroll;
}

.template:first-of-type {
  margin-top: 0;
}

.template {
  padding: var(--v3);
  border: 1px solid var(--disabled-text);
  margin: var(--v2) 0;
  border-radius: $border-radius;
  position: relative;
}

.selected {
  border: 2px solid var(--secondary-900);
}

.template-number {
  margin: var(--v1) var(--v3) var(--v1) 0;
}

.template-icons {
  position: absolute;
  top: 50%;
  right: var(--v2);
  font-size: var(--h5-font-size);
  transform: translateY(-50%);
}
