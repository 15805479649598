.tile-controller {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.calendar {
  width: 100%;
  height: 100%;
  transform: translateY(-6px);
}

@media only screen and (min-width: 1024px) {
  .calendar {
    font-size: 14px !important;
  }
}
