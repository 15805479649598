.container {
  position: absolute;
  top: 0;
  right: -100%;
  width: 400px;
  height: 100%;
  background: white;
  z-index: 101;
  display: flex;
  flex-direction: column;
  padding: 20px;
  transition: all 300ms ease;
  box-shadow: var(--box-shadow);
}

.open {
  right: 0;
}

.title {
  padding: 15px 0;
}

.title p {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}

.shifts {
  flex: 1;
  width: 100%;
  overflow: scroll;
  padding: 0 10px;
}

.shift-card {
  cursor: pointer;
  margin: 20px 0;
}

.shift-card:first-child {
  margin-top: 0;
}
