@use "src/shared/styles/variables" as *;

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}

.tab {
  width: 50%;
  background: var(--secondary-100);
  height: 100%;
  padding: var(--v4) var(--v2);
  border-radius: $border-radius $border-radius 0 0;
  cursor: pointer;
  color: var(--secondary-700);
  transition: all 300ms ease;
}

.tab h3 {
  font-size: var(--h5-font-size);
}

.active {
  color: var(--orange);
  background: white;
  box-shadow: $box-shadow;
}

.slider-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.slider-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--secondary-300);
  height: 3px;
}

.slider {
  height: 3px;
  background: var(--orange);
  border-radius: $border-radius;
  width: 50%;
  position: absolute;
  top: 0;
  transition: all 0.3s ease;
}

.modal-close-icon {
  top: 0px;
  display: none;
}

@media screen and (min-width: $bp-md) {
  .tab h3 {
    font-size: var(--h3-font-size);
  }

  .modal-close-icon {
    display: block;
  }
}
