.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.card {
  border-radius: 15px;
  padding: 40px;
  box-shadow: var(--box-shadow);
  margin: 40px 20px;
  width: 25vw;
  height: 500px;
  cursor: pointer;
}

.card p {
  margin: 30px 0;
}

.card li {
  margin: 10px 0;
}
