@use "src/shared/styles/variables" as *;

.container {
  margin-top: var(--v2);
  border: 1px solid var(--border-darker);
  border-radius: $border-radius;
}

.slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  width: 100%;
  flex-direction: column;
  padding: var(--v2);
}

.star {
  color: var(--orange);
  margin: 0 var(--v2);
  font-size: var(--h4-font-size);
}
