@use "src/shared/styles/variables" as *;

@media (min-width: $bp-sm) {
  .flex-container > div {
    padding-right: var(--v4);
  }

  .flex-container > div:last-child {
    padding-right: 0;
  }
}
