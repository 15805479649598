@use "src/shared/styles/variables" as *;

.container {
  border-radius: $border-radius;
  padding: var(--v3);
  box-shadow: $box-shadow;
  position: relative;
  margin: var(--v3) 0;
  transition: all 300ms ease;
}

.container * {
  transition: all 300ms ease;
}

.container *:not(.no-color-transition) {
  transition-property: background, border, transform, opacity, box-shadow, filter;
}

.container.incomplete {
  background: var(--greyscale-50);
}

.icon-container {
  position: absolute;
  top: 40px;
  font-size: 28px;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading {
  text-align: left;
  margin-top: var(--v3);
  padding: var(--v2) var(--v4);
  padding-top: 80px;
}

.heading span {
  color: var(--orange);
  margin-right: var(--v2);
}
.heading.open {
  padding-top: 100px;
}

.heading.complete span {
  color: var(--success-500);
}

.heading.incomplete {
  color: var(--secondary-600);
}

.heading.incomplete span {
  color: var(--secondary-300);
}
