@use "src/shared/styles/variables" as *;

.modal {
  position: fixed;
  top: var(--v3);
  left: 50%;
  transform: translateX(-50%);
  background: white;
  opacity: 0;
  visibility: hidden;
  z-index: 101;
  padding: var(--v3);
  border-radius: $border-radius;
  width: calc(100% - var(--v4));
  max-width: $bp-lgt;
  height: $bp-smd;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.visible {
  opacity: 1;
  visibility: visible;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.modal-header svg {
  width: 20px;
  height: 100%;
  cursor: pointer;
}

.modal-divider {
  border-bottom: 1px solid var(--border);
}

.close-icon {
  position: absolute;
  right: var(--v3);
  top: -10px;
  cursor: pointer;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

@media screen and (min-width: $bp-smd) {
  .modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: $bp-lg) {
  .modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% + var(--v3)));
  }
}
