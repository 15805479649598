@use "src/shared/styles/variables" as *;
.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: var(--orange);
}

.content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content img {
  display: block;
}

.icon {
  width: 140px;
  margin: 40px 0;
}

.shiftly-australia {
  width: 200px;
}

.blob {
  position: absolute;
  z-index: 1;
}

.blob-1 {
  top: -25%;
  right: -50%;
  width: 120vw;
}

.blob-2 {
  bottom: 20%;
  right: -20%;
  width: 50vw;
}
.blob-3 {
  bottom: -25%;
  left: -50%;
  width: 120vw;
}

.spinner {
  margin: 80px 0;
}

@media screen and (min-width: $bp-sm) {
  .blob-1 {
    width: 60vw;
    top: -40%;
    right: -20%;
  }

  .blob-2 {
    width: 20vw;
    bottom: -15%;
    right: 10%;
  }

  .blob-3 {
    width: 50vw;
    bottom: -30%;
    left: -15%;
  }
}
