@use "src/shared/styles/variables" as *;

// Break Points
$breakpoints: (
  xxs: $bp-xxs,
  xs: $bp-xs,
  sm: $bp-sm,
  smd: $bp-smd,
  md: $bp-md,
  lgt: $bp-lgt,
  lg: $bp-lg,
  xl: $bp-xl,
  xxl: $bp-xxl,
  xxxl: $bp-xxxl
);

.w-auto { width: auto; }
.w-100 { width: 100%; }
.w-75 { width: 75%; }
.w-60 { width: 60%; }
.w-50 { width: 50%; }
.w-40 { width: 40%; }
.w-25 { width: 25%; }
.w-20 { width: 20%; }

.h-auto { height: auto; }
.h-100 { height: 100%; }
.h-75 { height: 75%; }
.h-50 { height: 50%; }
.h-25 { height: 25%; }
.h-20 { height: 20%; }

.position-relative { position: relative; }
.position-absolute { position: absolute; }
.position-fixed { position: fixed; }
.position-sticky { position: sticky; }
.position-static { position: static; }

.cover {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

// Utility Mixin
@mixin responsive-class($bp, $suffix) {
  @media (min-width: $bp) {
    .#{$suffix}-w-auto { width: auto; }
    .#{$suffix}-w-100 { width: 100%; }
    .#{$suffix}-w-75 { width: 75%; }
    .#{$suffix}-w-60 { width: 60%; }
    .#{$suffix}-w-50 { width: 50%; }
    .#{$suffix}-w-40 { width: 40%; }
    .#{$suffix}-w-25 { width: 25%; }
    .#{$suffix}-w-20 { width: 20%; }

    .#{$suffix}-h-auto { height: auto; }
    .#{$suffix}-h-100 { height: 100%; }
    .#{$suffix}-h-75 { height: 75%; }
    .#{$suffix}-h-50 { height: 50%; }
    .#{$suffix}-h-25 { height: 25%; }
    .#{$suffix}-h-20 { height: 20%; }

    .#{$suffix}-position-relative { position: relative; }
    .#{$suffix}-position-absolute { position: absolute; }
    .#{$suffix}-position-fixed { position: fixed; }
    .#{$suffix}-position-sticky { position: sticky; }
    .#{$suffix}-position-static { position: static; }
  }
}

// Generate classes for all breakpoints
@each $suffix, $bp in $breakpoints {
  @include responsive-class($bp, $suffix);
}
