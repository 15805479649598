@use "src/shared/styles/variables" as *;

.modal {
  width: 100%;
  height: auto;
  z-index: 110;

}

.modal-content {
  padding: var(--v3);
}

@media screen and (min-width: $bp-sm) {
  .modal {
    min-width: 700px;
    width: 40vw;
  }
}
