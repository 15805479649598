@use "src/shared/styles/variables" as *;

:root {
  --h1-font-size: 3rem;
  --h2-font-size: 2.5rem;
  --h3-font-size: 2rem;
  --h4-font-size: 1.5rem;
  --h5-font-size: 1.25rem;
  --h6-font-size: 1.125rem;
  --p-font-size: 1rem;
  --label-font-size: 0.875rem;
  --caption-font-size: 0.75rem;
}

/* Plus Jakarta Sans - Extra Light */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

/* Plus Jakarta Sans - Extra Light Italic */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

/* Plus Jakarta Sans - Light */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

/* Plus Jakarta Sans - Light Italic */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

/* Plus Jakarta Sans - Regular */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Plus Jakarta Sans - Italic */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

/* Plus Jakarta Sans - Medium */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* Plus Jakarta Sans - Medium Italic */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

/* Plus Jakarta Sans - Semi Bold */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* Plus Jakarta Sans - Semi Bold Italic */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

/* Plus Jakarta Sans - Bold */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Plus Jakarta Sans - Bold Italic */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

/* Plus Jakarta Sans - Extra Bold */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

/* Plus Jakarta Sans - Extra Bold Italic */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/Plus Jakarta Sans/PlusJakartaSans-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

/* General Styles */
h1 {
  font-size: var(--h1-font-size);
  font-weight: 600;
}
h2 {
  font-size: var(--h2-font-size) !important;
  font-weight: 600;
}
h3 {
  font-size: var(--h3-font-size);
  font-weight: 600;
}
h4 {
  font-size: var(--h4-font-size);
  font-weight: 600;
}
h5 {
  font-size: var(--h5-font-size);
  font-weight: 600;
}
h6 {
  font-size: var(--h6-font-size);
  font-weight: 600;
}

p {
  font-size: var(--p-font-size);
  line-height: 1.8;
  font-weight: 400;
}

.caption {
  font-size: var(--caption-font-size) !important;
}

.label {
  font-size: var(--label-font-size) !important;
}

.display {
  font-size: var(--display-font-size) !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.orange {
  color: var(--orange);
}

.nowrap {
  white-space: nowrap;
}
