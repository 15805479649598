@use "src/shared/styles/variables" as *;

.container {
  height: calc(100vh - 90px);
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.header {
  position: relative;
  overflow: hidden;
  height: 200px;
}

.header-top {
  background: var(--secondary-500);
}

.header-bottom {
  background: white;
  border-bottom: 1px solid var(--border);
}

.header-card {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  background: white;
  border-radius: $border-radius;
  box-shadow: $border-radius;
  padding: var(--v2);
  z-index: 2;
}

.business-title {
  padding: var(--v1) 0 var(--v2) 0;
  border-bottom: 1px solid var(--border);
  text-align: center;
}

/* Locations */
.location {
  padding: var(--v1);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid var(--border);
}

.location-logo {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  overflow: hidden;
  margin-right: var(--v2);
}

.radio-btn {
  margin-left: auto;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid var(--secondary-900);
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-btn-inner {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: white;
}

.radio-btn.selected {
  background: var(--secondary-900);
}

/* Body */

.body {
  padding: var(--v3);
  flex: 1;
}

.logout {
  border-bottom: 1px solid var(--border);
  border-top: 1px solid var(--border);
  padding: var(--v3) var(--v3) var(--v4) var(--v3);
}

/* Blobs */

.blob {
  position: absolute;
  z-index: 1;
  border-radius: 50%;
}

.blob-1 {
  top: -15%;
  left: -10%;
  width: 30vw;
  height: 30vw;
  background: var(--secondary-300);
}

.blob-2 {
  top: 15%;
  right: -5%;
  width: 25vw;
  height: 25vw;
  background: var(--secondary-300);
}
