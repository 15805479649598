@use "src/shared/styles/variables" as *;

.logo {
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.drop {
  transform: translateY(10px);
}
