.container {
  width: 100%;
  height: 100%;
  background: var(--secondary-100);
  display: flex;
  flex-direction: column;
}

.heading {
  background: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  position: relative;
}

.heading p {
  margin-right: 10px;
}

.heading input {
  border-bottom: 1px solid var(--secondary-300);
  width: 400px;
  padding: 5px;
}

.shift-tray-btn {
  margin-left: auto;
}

.shift-tray-btn button {
  padding: 10px 15px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 100%;
}

.messages {
  width: 100%;
  overflow: scroll;
  max-height: 67vh;
  padding-bottom: 20px;
}

.interaction-bar {
  width: calc(100% - 80px);
}

/* No Message */
.no-message-container {
  position: absolute;
  width: 100%;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.no-message-text {
  text-align: center;
}

.no-message-image {
  width: 500px;
  margin: 0 auto;
}

.no-message-image img {
  width: 100%;
}

.no-message-text h3 {
  margin: 0 0 20px 0;
}

.no-message-text h3,
.no-message-text h5 {
  font-weight: 500;
}

/* Search Dropdown */
.input-dropdown {
  position: absolute;
  top: 100%;
  left: 50px;
  background: white;
  box-shadow: var(--box-shadow);
  border-radius: 0 0 15px 15px;
  width: 400px;
  max-height: 300px;
  overflow: scroll;
  z-index: 99;
  transition: all 200ms ease;
  transform: scaleY(0);
  transform-origin: top;
}

.input-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 98;
}

.visible {
  transform: scaleY(1);
}

.relevant-shifter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  border-bottom: 1px solid var(--border);
  cursor: pointer;
}

.relevant-shifter:hover {
  background: var(--secondary-100);
}
.dropdown-initial {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: white;
  background: var(--secondary-900);
  margin-right: 20px;
  overflow: hidden;
  border: 2px solid var(--secondary-900);
  font-weight: 600;
}

.dropdown-details p {
  font-weight: 600;
}
