@use "src/shared/styles/variables" as *;

.cards {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow: scroll;
}

.card {
  border-radius: $border-radius;
  border: 2px solid var(--secondary-900);
  padding: var(--v2);
  height: 250px;
  width: 100%;
  max-width: 250px;
  margin: var(--v2) 0;
}

.location-profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid var(--secondary-900);
  overflow: hidden;
}

@media screen and (min-width: $bp-sm) {
  .card {
    margin-right: var(--v2);
  }
}
