.trash-icon {
  position: absolute;
  top: 5px;
  right: 15px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: var(--secondary-200);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--error);
  font-size: 8px;
}
