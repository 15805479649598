@use "src/shared/styles/variables" as *;

.chevron {
  transform: rotate(0deg);
  transition: all 200ms ease;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transform-origin: center;
  cursor: pointer;
}

.chev-open {
  transform: translateY(-50%) rotate(180deg);
}

.placeholder {
  color: var(--placeholder-color);
  white-space: nowrap;
}

.search-input {
  height: 100%;
  width: 100%;
  font-size: var(--label-font-size);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected-values {
  white-space: nowrap;
  font-size: var(--label-font-size);
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 95%;
  left: 0;
  right: 0;
  border: 1px solid var(--border);
  background-color: white;
  z-index: 101;
  border-radius: 0 0 $border-radius $border-radius;
  box-shadow: $box-shadow;
  overflow-y: auto;
  margin-top: 4px;
  transition: all 0.2s ease;
  height: 0;
  opacity: 0;
}

.isOpen {
  height: auto;
  max-height: 180px;
  opacity: 1;
}

.mobile-isOpen {
  max-height: 280px;
}

.option {
  padding: var(--v2);
  font-size: var(--label-font-size);
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.option:hover {
  background-color: var(--secondary-50);
}

.focused-option {
  background-color: var(--secondary-50);
}

.selected-option {
  background-color: var(--primary-50);
  color: var(--orange);
  font-weight: 500;
}

.special-btn {
  position: absolute;
  left: 0px;
  bottom: -0.5px;
  background: var(--secondary-100);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  transform: translateX(-100%);
  border: 0.5px solid var(--secondary-400);
  border-radius: $border-radius 0 0 $border-radius;
  cursor: pointer;
}
