@use "src/shared/styles/variables" as *;

// Break Points
$breakpoints: (
  xxs: $bp-xxs,
  // Micro (XXS) - Small phones (iPhone SE, older Androids)
  xs: $bp-xs,
  // Small Phones (XS) - Standard mobile (iPhone 12 Mini, Galaxy S10e)
  sm: $bp-sm,
  // Large Phones (SM) - Big-screen phones (iPhone 15 Pro Max, Galaxy S24 Ultra)
  smd: $bp-smd,
  // Foldables (SMD) - Foldable devices (Samsung Galaxy Fold, Surface Duo)
  md: $bp-md,
  // Tablets (MD) - iPad Mini, budget Android tablets
  lgt: $bp-lgt,
  // Large Tablets (LGT) - iPad Air/Pro, Surface Go
  lg: $bp-lg,
  // Small Laptops (LG) - 13" MacBook Air, small Chromebooks
  xl: $bp-xl,
  // Standard Laptops (XL) - Most work laptops, 1080p monitors
  xxl: $bp-xxl,
  // Large Desktops (XXL) - Ultra-wide screens, gaming rigs
  xxxl: $bp-xxxl // 4K Screens (XXXL) - High-res displays, TV monitors,,,,,,,,,,,,,,,,
);

:root {
  --v1: 8px;
  --v2: 16px;
  --v3: 24px;
  --v4: 32px;
  --v5: 48px;
  --v6: 64px;
  --v7: 96px;
}

// Mixin for spacing utilities
@mixin spacing-utils($property, $short) {
  .#{$short}-0 {
    #{$property}: 0 !important;
  }
  .#{$short}-1 {
    #{$property}: var(--v1) !important;
  }
  .#{$short}-2 {
    #{$property}: var(--v2) !important;
  }
  .#{$short}-3 {
    #{$property}: var(--v3) !important;
  }
  .#{$short}-4 {
    #{$property}: var(--v4) !important;
  }
  .#{$short}-5 {
    #{$property}: var(--v5) !important;
  }
  .#{$short}-6 {
    #{$property}: var(--v6) !important;
  }
  .#{$short}-7 {
    #{$property}: var(--v7) !important;
  }
  .#{$short}-auto {
    #{$property}: auto !important;
  }
}

// Generate base spacing classes
@include spacing-utils("padding", "p");
@include spacing-utils("margin", "m");

// Mixin for directional spacing
@mixin directional-spacing($property, $prefix) {
  @each $dir,
    $css-dir
      in (
        x: (
          left,
          right,
        ),
        y: (
          top,
          bottom,
        ),
        t: (
          top,
        ),
        b: (
          bottom,
        ),
        l: (
          left,
        ),
        r: (
          right,
        )
      )
  {
    .#{$prefix}#{$dir}-0 {
      @each $side in $css-dir {
        #{$property}-#{$side}: 0;
      }
    }
    .#{$prefix}#{$dir}-1 {
      @each $side in $css-dir {
        #{$property}-#{$side}: var(--v1);
      }
    }
    .#{$prefix}#{$dir}-2 {
      @each $side in $css-dir {
        #{$property}-#{$side}: var(--v2);
      }
    }
    .#{$prefix}#{$dir}-3 {
      @each $side in $css-dir {
        #{$property}-#{$side}: var(--v3);
      }
    }
    .#{$prefix}#{$dir}-4 {
      @each $side in $css-dir {
        #{$property}-#{$side}: var(--v4);
      }
    }
    .#{$prefix}#{$dir}-5 {
      @each $side in $css-dir {
        #{$property}-#{$side}: var(--v5);
      }
    }
    .#{$prefix}#{$dir}-6 {
      @each $side in $css-dir {
        #{$property}-#{$side}: var(--v6);
      }
    }
    .#{$prefix}#{$dir}-7 {
      @each $side in $css-dir {
        #{$property}-#{$side}: var(--v7);
      }
    }
    .#{$prefix}#{$dir}-auto {
      @each $side in $css-dir {
        #{$property}-#{$side}: auto;
      }
    }
  }
}

// Generate spacing utilities for directions
@include directional-spacing("padding", "p");
@include directional-spacing("margin", "m");

// Generate responsive spacing utilities
@mixin responsive-spacing($bp, $suffix) {
  @media (min-width: $bp) {
    @include spacing-utils("padding", "#{$suffix}-p");
    @include spacing-utils("margin", "#{$suffix}-m");
    @include directional-spacing("padding", "#{$suffix}-p");
    @include directional-spacing("margin", "#{$suffix}-m");
  }
}

// Apply responsive spacing for each breakpoint
@each $suffix, $bp in $breakpoints {
  @include responsive-spacing($bp, $suffix);
}
