@use "src/shared/styles/variables" as *;

.container {
  height: 100vh;
  width: 100vw;
  background: white;
  position: relative;
  padding: 0 1px;
  align-items: center;
  justify-content: center;
}

.card {
  background: white;
  border-radius: $border-radius;
  padding: var(--v2);
  transition: all 200ms ease;
}

@media screen and (min-width: $bp-sm) {
  .container {
    display: flex;
  }
  .card {
    padding: var(--v5);
    width: 1024px;
  }
}

@media screen and (min-width: $bp-lg) {
  .container {
    background: url("./assets/AuthBackground.png") no-repeat left center;
    background-size: cover;
  }

  .card {
    box-shadow: $box-shadow;
  }
}
