@use "src/shared/styles/variables" as *;

.wrapper {
  width: 100%;
  position: relative;
  padding: 0px;
  margin-bottom: var(--v2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.container {
  border-radius: $border-radius;
  padding: 7px 3px;
  margin: var(--v1) 0;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.increment-unit-input {
  margin: 0 var(--v3);
}

.input {
  width: 50px;
  text-align: center;
  border: none;
  outline: none;
  background: none;
  box-sizing: border-box;
  line-height: 2;
}

.increment-btn {
  background: var(--secondary-900);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 50px;
  border-radius: $border-radius;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.increment-btn:disabled {
  background: var(--disabled-background);
  color: rgb(170, 170, 170);
  cursor: not-allowed;
}

.unit {
  margin-right: var(--v3);
}
