@use "src/shared/styles/variables" as *;

.primary-image {
  border-radius: $border-radius;
  height: 140px;
  overflow: hidden;
  margin: var(--v3) 0;
  cursor: pointer;
}

.icon-image {
  width: 150px;
  height: 150px;
  margin: var(--v3) 0;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.draggable-image-container {
  width: 25%;
  height: 100%;
  padding: 0 var(--v1);
  position: relative;
}

.draggable-image-container img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
}

.no-image {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--secondary-100);
  border-radius: $border-radius;
  cursor: pointer;
}

.no-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--secondary-200);
  display: flex;
  align-items: center;
  justify-content: center;
}
