@use "src/shared/styles/variables" as *;

.cards-container {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  gap: var(--v2);
}

@media screen and (min-width: $bp-md) {
  .cards-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
.radio-button-container {
  padding: var(--v1) var(--v2);
  border-bottom: 1px solid var(--border);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: $border-radius;
  border: 1px solid transparent;
  margin: var(--v1) 0;
}

.radio-button-container:hover .radio-button {
  border: 1px solid var(--secondary-900);
}

.radio-button-container:last-child {
  border-bottom: none;
}

.radio-button-container.active {
  border: 1px solid var(--secondary-900);
}

.radio-button-container.active .radio-button {
  border: 1px solid var(--secondary-900);
  background: var(--secondary-900);
  color: white;
}

.radio-button-card {
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow);
  border: 1px solid var(--border);
}

.radio-button-card:hover {
  border: 1px solid var(--secondary-700);
}

.radio-button-card.active {
  border: 2px solid var(--secondary-900);
}

.radio-button {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid var(--secondary-900);
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-button-inner {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: white;
}
