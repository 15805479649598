.container {
  max-height: 450px;
  min-width: 350px;
  max-width: 100%;
  margin: 0 auto;
  width: 30vw;
}

.container img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
