@use "src/shared/styles/variables" as *;

.slot {
  width: 12.5%;
  border: 1px solid var(--border);
  height: 100px;
}

.shift-selected {
  background: var(--secondary-100);
  border: 1px solid var(--secondary-900);
}

.dropping {
  background: var(--secondary-100);
  border: 1px dashed var(--secondary-900);
}

.shift-slot {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 300ms ease;
}

.free:hover {
  background: var(--secondary-100);
}

.disabled {
  opacity: 0.4;
  cursor: auto;
}

.expired {
  opacity: 0.6;
}

.info-container {
  position: relative;
  padding: var(--v1);
  height: 100%;
  width: 100%;
}

.info-container p span {
  white-space: nowrap;
}

.shiftly-image {
  display: none;
  position: absolute;
  right: var(--v1);
  bottom: var(--v1);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  overflow: hidden;
  background: white;
}

.shiftly-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

@media screen and (min-width: $bp-lg) {
  .shiftly-image {
    display: flex;
  }
}

.linkArrow {
  margin-left: 10px;
}

.status-text,
.confirmed-shifter p {
  font-size: var(--caption-font-size);
  margin-top: var(--v1);
}

.applicants-link {
  margin-top: 6px;
}

.confirmed-shifter p {
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hide {
  opacity: 0;
  pointer-events: none;
}
