@use "src/shared/styles/variables" as *;

.container {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
}

.content {
  flex: 1;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}
