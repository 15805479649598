.shiftly-logo {
  width: 35px;
  margin-right: var(--v4);
}

.container span {
  color: var(--orange);
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: var(--v3);
}
