@use "src/shared/styles/variables" as *;

.table {
  border-radius: $border-radius;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 10vh;
}

.table thead {
  background-color: var(--secondary-100);
  text-align: left;
}

.table th {
  padding: var(--v3);
  font-weight: 600;
}

.table th:first-child {
  border-radius: $border-radius 0 0 0;
}

.table th:last-child {
  border-radius: 0 $border-radius 0 0;
}

.table td {
  padding: var(--v1);
  border: 1px solid var(--border);
}

.table li {
  padding: var(--v1);
  margin-left: var(--v3);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.award {
  position: absolute;
  top: -30px;
  right: 0;
}

.stream {
  margin: 0 var(--v1);
  font-weight: 600;
  color: var(--orange);
}
