/* Onboarding */
.onboarding-heading {
  margin: var(--v4) 0 var(--v6) 0;
  text-align: center;
}

.explain {
  font-weight: 700;
  text-decoration: underline;
}

.onboarding-block {
  margin-bottom: var(--v5);
  border-bottom: 1px solid var(--border);
}

.onboarding-title {
  padding: 0 var(--v4);
  padding-top: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.onboarding-title h4 {
  border-bottom: 2px solid var(--orange);
}
