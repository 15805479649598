@use "src/shared/styles/variables" as *;

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content-wrapper {
  flex: 1;
  overflow-y: auto;
}
