@use "src/shared/styles/variables" as *;

.modal {
  min-width: none;
  width: 800px;
  height: auto;
  min-height: auto;
}

.submit-btn {
  width: 220px;
  transform: translateY(-15px);
}
