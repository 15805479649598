@use "src/shared/styles/variables" as *;

.modal {
  height: auto;
}

// Button
.action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  background: var(--secondary-900);
  border-radius: 6px;
  margin: 0 var(--v1);
  cursor: pointer;
  border: 2px solid transparent;
  position: relative;
  color: white;
  min-width: auto;
  padding: 0 var(--v3);
  font-size: var(--label-font-size);
  font-weight: 500;
  white-space: nowrap;
  color: white;
}

.action-btn:hover,
.open {
  background: var(--secondary-600);
}

.action-btn svg {
  margin-left: var(--v2);
}

//Mode
.send-modes {
  flex: 1;
  width: 100%;
}

.mode-container {
  border-radius: $border-radius;
  border: 1px solid var(--border);
  padding: var(--v2);
  margin: var(--v2) 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.mode-button {
  border-radius: 50%;
  border: 1px solid var(--border);
  padding: var(--v1);
  width: 15px;
  height: 15px;
  margin-right: var(--v3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mode-button.selected {
  border: 1px solid var(--secondary-900);
}

.mode-radio {
  border-radius: 50%;
  background: var(--secondary-800);
  min-width: 12px;
  height: 12px;
  visibility: hidden;
}

.mode-radio.selected {
  visibility: visible;
}

// New Update
.new-update {
  border-radius: $border-radius;
  background: var(--primary-50);
  color: var(--orange);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--v2);
  margin-top: var(--v3);
  width: 100%;
}
