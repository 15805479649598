@use "src/shared/styles/variables" as *;

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: var(--secondary-200);
  padding: var(--v2) var(--v3);
}

/* Controls */

.control {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  font-size: 10px;
  margin: 0 1px;
  box-shadow: $box-shadow;
  cursor: pointer;
}

.control-left {
  border-radius: 6px 0 0 6px;
}

.control-right {
  border-radius: 0 6px 6px 0;
}

.control-date {
  min-width: 180px;
}

.periods {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.control-date p {
  font-weight: 600;
  font-size: var(--label-font-size);
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: 100%;
}

.prev-period,
.next-period {
  position: absolute;
  top: 0;
}

.prev-period {
  left: -100%;
}

.next-period {
  right: -100%;
}

/* Calendar */
.calendar {
  position: absolute;
  bottom: -10px;
  left: 0;
  z-index: 99;
  transform: translate(0%, 100%);
  opacity: 0;
  transition: 200ms all ease;
  pointer-events: none;
  width: 350px;
}

.visible {
  opacity: 1;
  pointer-events: all;
}

/* Action Buttons */

.action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--v2);
  height: 32px;
  background: white;
  border-radius: 6px;
  margin: 0 var(--v1);
  cursor: pointer;
  border: 2px solid transparent;
  position: relative;
}

.action-btn:hover,
.open {
  background: var(--secondary-100);
}

.action-btn p {
  font-size: var(--label-font-size);
  font-weight: 500;
  white-space: nowrap;
}

.action-btn svg {
  margin-right: var(--v2);
}

.multi-select-on {
  border: 2px solid var(--secondary-900);
}

.scheduler-templates {
  position: relative;
}

.click-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
