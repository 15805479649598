@use "src/shared/styles/variables" as *;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--secondary-50);
  color: var(--secondary-900);
  border-radius: $border-radius;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-right: var(--v4);
}

.clicked {
  background: var(--secondary-200);
}

.dropdown {
  position: absolute;
  min-width: 180px;
  bottom: -6px;
  left: 50%;
  background: white;
  border-radius: 0 0 $border-radius $border-radius;
  transform: translate(-100%, 100%);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  overflow: hidden;
  z-index: 100;
}

.action {
  width: 100%;
  padding: var(--v2);
}

.action:hover {
  background: var(--secondary-50);
}

.spacer {
  width: 100%;
  height: 1px;
  background: var(--secondary-200);

}
.disabled {
  pointer-events: none;
  opacity: 0.9;
}