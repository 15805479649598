.initial {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--primary-50);
  margin-right: var(--v2);
  color: var(--orange);
}

.initial p {
    font-size: large;
    font-weight: 500;
}
.row-container {
  padding: var(--v3) var(--v4);
}
