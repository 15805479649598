/* Shift Row */
@use "src/shared/styles/variables" as *;

.row-title {
  width: 12.5%;
  background: var(--secondary-50);
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border);
}

.row-title p {
  font-size: var(--label-font-size);
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 var(--v1);
}
