@use "src/shared/styles/variables" as *;

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--v3);
}

.time-selectors {
  border-bottom: 1px solid var(--border);
  height: 100px;
}

.time-select {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  margin-right: var(--v1);
  width: 100%;
}


