@use "src/shared/styles/variables" as *;

.total-container {
  padding: var(--v2);
  background: var(--secondary-100);
  border-radius: $border-radius;
  margin: var(--v1) 0;
  display: flex;
  flex-direction: column;
}

.total-lines {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}
.total-line {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: var(--v1) 0;
}

.total-line p {
  font-weight: 500;
  font-size: var(--label-font-size);
}

.total-line p span {
  display: inline-block;
  margin: 0 var(--v2);
}

.total-line div {
  height: 1px;
  background: var(--secondary-400);
  flex: 1;
  margin: 0 var(--v3);
}
