@use "src/shared/styles/variables" as *;

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid var(--secondary-900);
  overflow: hidden;
  margin: var(--v5) 0 var(--v4) 0;
}
