@use "src/shared/styles/variables" as *;

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 var(--v3);
}

.icon-container {
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  background: var(--secondary-100);
  color: var(--secondary-600);
  width: 40px;
  height: 40px;
  margin-right: var(--v2);
  transition: all 200ms ease;
}

.active .icon-container {
  background: var(--primary-100);
  color: var(--orange);
}

.link-text {
  color: var(--secondary-700);
  white-space: nowrap;
}

.active .link-text {
  color: var(--orange);
}

.link-text::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: var(--primary-500);
  transition: width 0.3s;
  border-radius: $border-radius;
  transform: translateY(2px);
}

.container:hover .link-text::after {
  width: 100%;
  transition: width 0.3s;
}

.container:hover {
  color: var(--secondary-600);
  cursor: pointer;
}

.badge {
  background: var(--orange);
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: -10px;
  font-size: 12px;
}

@media screen and (min-width: $bp-xl) {
  .container {
    margin: 0 var(--v2);
  }
  .icon-container {
    display: flex;
  }
}
