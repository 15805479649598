.modal {
  min-width: 65vw;
}

.shiftly-logo {
  width: 35px;
  margin-right: 40px;
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.label span {
  color: var(--orange);
}

.content {
  padding: 20px 80px;
}
.heading {
  text-align: center;
  width: 80%;
  margin: 20px auto 60px auto;
}

.heading h3 {
  margin: 20px 0;
}

.heading p {
  font-size: 14px;
  font-weight: 500;
}

.heading span {
  color: var(--orange);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.buttons button {
  width: 30%;
}
