@use "src/shared/styles/variables" as *;

.rate-picture-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 30px;
}

.rate-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid var(--secondary-900);
}
