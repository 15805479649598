@use "src/shared/styles/variables" as *;

.tabs-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab-item {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--neautral-500);
  padding: var(--v1);
}

.tab-item p {
  margin: 0 var(--v1);
}

.tab-item svg path {
  fill: var(--secondary-500);
}

.tab-item.active p {
  color: var(--orange-dark);
  font-weight: bold;
}

.tab-item.active svg path {
  fill: var(--orange);
}

.tab-slider-container {
  width: 100%;
  position: relative;
  height: 2px;
  background: var(--border);
  box-shadow: $box-shadow;
}

.tab-slider {
  height: 2px;
  position: absolute;
  top: 0;
  background: var(--orange);
  transition: all 0.3s ease;
}
