@use "src/shared/styles/variables" as *;


.container {
  padding-top: var(--v2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.heading {
  padding: var(--v3);
}

.heading p {
  text-align: center;
  margin-top: 20px;
}

.content {
  padding: var(--v3);
  width: 100%;
  margin: 0 auto;
}

.qualifications-container {
  overflow: scroll;
  transition: all 0.3s ease;
  gap: 0 var(--v2);
  max-width: 650px;
  margin-inline: auto;
}

.qualification {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
