@use "src/shared/styles/variables" as *;
.account-icon {
  border-radius: 50%;
  background-color: var(--secondary-100);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 var(--v4);
  cursor: pointer;
  margin-right: var(--v2);
  width: 50px;
  height: 50px;
}

.icon-chevron {
  transition: all 0.3s ease;
}

.spin-chevron {
  transform: rotate(180deg);
}

.menu-container {
  background: white;
  min-width: 300px;
  position: fixed;
  top: 0px;
  right: 0;
  box-shadow: var(--box-shadow);
  padding: var(--v3);
  border-radius: 0 0 $border-radius $border-radius;
  z-index: 99;
  transition: all 0.3s ease;
  transform: translateY(-100%);
}

.tablet-menu-container {
  border-radius: $border-radius;
  top: 20px;
  right: 20px;
  transform: translateY(-110%);
}

.show {
  transform: translateY(0px);
}

.parent-nav-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.link-text {
  margin-left: var(--v3);
  transform: translateY(2px);
}

.sub-nav-item {
  margin-left: var(--v1);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.sub-nav-icon {
  width: 20px;
}

.sub-nav-side {
  background: var(--secondary-300);
  width: 2px;
  height: 50px;
  position: relative;
}

.sub-nav-side::after {
  content: "";
  width: 10px;
  height: 2px;
  background: var(--secondary-300);
  position: absolute;
  top: 50%;
}

.active {
  color: var(--orange);
}

.link-text::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: var(--primary-500);
  transition: width 0.3s;
  border-radius: 25px;
  transform: translateY(2px);
}

.sub-nav-content:hover .link-text::after {
  width: 100%;
  transition: width 0.3s;
}

.parent-nav-item:hover .link-text::after {
  width: 100%;
  transition: width 0.3s;
}
