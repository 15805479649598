@use "src/shared/styles/variables" as *;

.container {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: $box-shadow;
  background: white;
  height: 95px;
}

.active p {
  color: var(--orange-dark);
  font-weight: bold !important;
}

.active svg path {
  fill: var(--orange);
}

.icon {
  color: var(--secondary-700);
  margin-bottom: var(--v1);
  width: 25px;
  height: 25px;
}

.badge {
  position: absolute;
  top: -10px;
  right: -15px;
  background: var(--orange);
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
