@use "src/shared/styles/variables" as *;
.container {
  background: var(--secondary-100);
  padding: var(--v3) 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}

.container img {
  object-fit: contain;
}

.content-wrapper {
  max-width: $bp-lgt;
  background: white;
  border-radius: $border-radius;
  width: 100%;
  min-height: 80vh;
  transition: all 200ms ease;
  box-shadow: $box-shadow;
  display: flex;
  flex-direction: column;
  padding-bottom: var(--v6);
}

.content {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: var(--v4);
}

.illustration {
  height: 40vh;
  width: 100%;
  margin: var(--v4) auto;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: $bp-sm) {
  .illustration {
    width: 60%;
  }
}
