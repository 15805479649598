@use "src/shared/styles/variables" as *;

.container {
  box-shadow: $box-shadow;
  padding: var(--v1) var(--v2);
  min-height: 80px;
  border-radius: $border-radius;
  margin: var(--v2) 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.border {
  border: 1px solid var(--orange);
}

.image {
  color: var(--orange);
  font-size: 20px;
  margin-right: var(--v3);
}

.trash-icon {
  color: var(--error);
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
}

.default-method {
  font-size: 14px;
  position: absolute;
  left: 0%;
  bottom: -30px;
}

.loading-spinner {
  color: var(--orange);
  font-size: 20px;
  margin-top: 20px;
  margin-left: 50%;
}

.body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grey-out {
  opacity: 0.8;
  background: var(--disabled-background);
}
