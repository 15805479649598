.container {
  box-shadow: 5px 0px 15px 1px rgba(200, 200, 200, 0.25);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.new-message-btn {
  width: calc(100% - 40px);
  margin: 20px;
}

.message-options svg {
  margin-left: 20px;
  font-size: 18px;
  cursor: pointer;
}

.search-container {
  padding: 0 20px;
}

.messages {
  flex: 1;
  overflow-y: scroll;
}

.no-messages {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-messages p {
  font-size: 16px;
  color: var(--secondary-300);
  text-align: center;
  transform: translateY(-100px);
}

/* Convo Card */

.convo-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  border-radius: 10px;
  position: relative;
}

.convo-container:hover {
  background: var(--secondary-100);
}

.convo-profile-image {
  margin-right: 15px;
}

.convo-initials-icon {
  background: var(--secondary-900);
  color: white;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.convo-profile-image img {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  border: 2px solid var(--secondary-900);
}

.convo-details h4 {
  font-size: 16px;
}

.convo-details p {
  font-size: 14px;
  margin-top: 5px;
}

.active-convo {
  background: var(--secondary-100);
}

.active-convo:hover {
  background: var(--secondary-100);
}

.active-convo .convo-profile-image img {
  border: 2px solid white;
}

.chevron {
  margin-left: auto;
  font-size: 10px;
  color: var(--secondary-900);
}

.preview svg {
  margin-right: 10px;
  transform: translateY(-1px);
}

.hasUnread {
  background: var(--secondary-100);
}

.hasUnread p,
.hasUnread h5 {
  font-weight: 700;
  color: var(--secondary-900);
}
