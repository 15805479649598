@use "src/shared/styles/variables" as *;

.logo {
  width: 150px;
  margin: var(--v4) auto;
}

.content {
  padding: var(--v3);
}

.content p {
  width: 100%;
  margin: var(--v2) auto;
  font-size: var(--label-font-size);
}

.content ul {
  text-align: left;
  width: 100%;
  margin: 0 auto;
}

.content ul li {
  margin: var(--v2) var(--v1);
  font-size: var(--label-font-size);
  line-height: 1.5;
}

.content ul li span {
  font-weight: 600;
  font-style: italic;
}

@media screen and (min-width: $bp-sm) {
  .content p {
    width: 70%;
  }

  .content ul {
    width: 70%;
  }
}
