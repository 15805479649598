@use "src/shared/styles/variables" as *;

.container {
  background: white;
  width: 300px;
  position: fixed;
  z-index: 1000;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  overflow: hidden;
}

.row-section {
  border-bottom: 1px solid var(--border);
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--v2) var(--v2);
  width: 100%;
  cursor: pointer;
}

.row:hover {
  background: var(--secondary-100);
}



