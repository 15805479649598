@use "src/shared/styles/variables" as *;

.table {
  flex: 1;
  width: 100%;
  // padding: 0 var(--v2);
}

.sync {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sync p {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

.sync.pending {
  background-color: var(--primary-50);
  color: var(--orange);
}

.sync.synced {
  background-color: var(--success-50);
  color: var(--success-dark);
}

.sync.incomplete {
  background-color: var(--greyscale-100);
}

.sync.pending,
.sync.synced,
.sync.incomplete {
  padding: 5px 10px;
  border-radius: 25px;
}

.sync.outofsync,
.sync.unsynced,
.sync.failed {
  background-color: white;
  border: 1px solid var(--secondary-900);
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.sync.outofsync p,
.sync.unsynced p,
.sync.failed p {
  margin-right: 10px;
}

.xero-logo {
  width: 20px;
  height: 20px;
  margin-left: var(--v2);
}

.import-content {
  margin-bottom: var(--v3);
}
.import-content span {
  font-weight: 600;
  font-style: normal;
  display: inline;
}

.import-content p {
  margin: var(--v2) 0;
}

.import-content li {
  margin: var(--v2);
}
