.container {
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

.container span {
  color: var(--orange);
}

.container span svg {
  margin-left: 10px;
}
