@use "src/shared/styles/variables" as *;

input,
textarea {
  background: none;
  padding: 0;
  outline: none;
  border: none;
  width: 100%;
  font-size: var(--label-font-size);
  box-sizing: border-box;
  line-height: 2;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type="time"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

input::placeholder,
textarea::placeholder {
  color: var(--placeholder-color) !important;
  font-size: var(--label-font-size);
}

// Input Wrapper
.input-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: var(--v2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px;
}

.input-container {
  width: 100%;
  border: 1px solid transparent;
  border-radius: $border-radius;
  padding: var(--v1) var(--v2);
  background: var(--input-background);
  border: 0.5px solid var(--secondary-400);
  box-sizing: border-box;
  position: relative;
  min-height: 50px;
  display: flex;
  align-items: center;
}

.input-container:focus-within {
  outline: 1px solid var(--secondary-400, #94a3b9);
  box-sizing: border-box;
}

.input-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.input-label {
  font-weight: 500;
  margin-bottom: var(--v1);
  white-space: nowrap;
  font-size: var(--label-font-size);
}

.input-label span {
  color: var(--orange);
}

.input-link {
  font-size: var(--label-font-size);
  font-weight: 500;
  color: var(--secondary-900);
  margin-bottom: var(--v1);
}

.input-error-container {
  background: var(--error-light);
  border-radius: $border-radius;
  margin-top: var(--v1);
  padding: 0 var(--v2);
}

.input-message {
  color: var(--error-dark);
  font-size: var(--caption-font-size);
}
