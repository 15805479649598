.wrapper {
  margin-bottom: var(--v2);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.checkbox {
  border: 1px solid var(--border);
  width: 25px;
  height: 25px;
  border-radius: 6px;
  margin-right: var(--v2);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox.checked {
  background: var(--blue);
}
