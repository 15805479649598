.profile-picture {
  height: 35px;
  min-width: 35px;
  border-radius: 50%;
  overflow: hidden;
}

.modal {
  text-align: center;
  height: auto;
}

.cell {
  width: 180px;
}

.cell p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.warning-message {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--error);
}
