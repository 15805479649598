.toggle-container {
  position: relative;
  display: flex;
  width: 360px;
  height: 50px;
  background-color: #e3eaf0;
  border-radius: 9999px;
  padding: 4px;
  overflow: hidden;
}

.toggle-container * {
  user-select: none;
}

.toggle-indicator {
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(50% - 5px);
  height: 42px;
  background-color: #ffffff;
  border-radius: 9999px;
  z-index: 0;
}

.toggle-button {
  flex: 1;
  border: none;
  background: transparent;
  font-weight: 600;
  z-index: 1;
  color: #5f6c7b;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  cursor: pointer;

  &.active {
    color: #000000;
  }
}

.withIcon {
  padding-right: 20px;
}
