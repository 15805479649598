@use "src/shared/styles/variables" as *;

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 200ms ease;
  pointer-events: none;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  background: white;
  opacity: 0;
  z-index: 102;
  width: 400px;
}

.visible {
  opacity: 1;
  pointer-events: all;
}

.heading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: var(--v1);
}

.heading-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: var(--v1);
  margin-right: var(--v2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--v1);
}

.heading-image.confirmed img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.heading-image.published {
  border: 1px solid var(--orange);
}

.heading-image.complete {
  border: 1px solid var(--success);
}

.heading-image.unpublished {
  border: 1px solid var(--border);
}

.heading-image.hasApplicants {
  border: 1px solid var(--warning-500);
}

.heading-image.confirmed {
  padding: 0;
}

.heading-image.confirmed,
.heading-image.expired {
  border: 1px solid var(--secondary-900);
}

.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: var(--h5-font-size);
}

.heading-status {
  font-size: var(--label-font-size);
}

.heading.published {
  border-bottom: 3px solid var(--orange);
}

.heading.complete {
  border-bottom: 3px solid var(--success);
}

.heading.unpublished {
  border-bottom: 3px solid var(--secondary-400);
}

.heading.hasApplicants {
  border-bottom: 3px solid var(--warning-500);
}
.heading.confirmed,
.heading.expired {
  border-bottom: 3px solid var(--secondary-900);
}

.body {
  padding: var(--v1) var(--v3);
  font-size: var(--label-font-size);
  font-weight: 500;
  margin-bottom: 40px;
}

.body-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: var(--v1) 0;
}

.body-icon {
  width: 40px;
  font-size: var(--p-font-size);
}

.footer {
  padding: var(--v2);
  border-top: 1px solid var(--border);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-subtext {
  margin-bottom: var(--v1);
  font-size: var(--caption-font-size);
  color: var(--secondary-400);
}

.edit-button {
  padding: var(--v1);
}

.linkArrow {
  margin-left: var(--v1);
}

.editing-published {
  font-size: var(--caption-font-size);
  text-align: center;
  font-weight: 500;
  margin: var(--v1);
}
