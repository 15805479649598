@use "src/shared/styles/variables" as *;

.container {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: $border-radius;
  background: white;
  box-shadow: $box-shadow;
  padding: var(--v2);
  margin: var(--v3) 0;
  cursor: pointer;
  position: relative;
  z-index: 50;
  border: 1px solid transparent;
}

.container:hover {
  border: 1px solid var(--secondary-1000);
}

.container.active {
  border: 1px solid var(--secondary-1000);
}

.container.no-hover:hover {
  background: white;
  transform: translateX(0);
}

.cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--v1);
  border-right: 1px solid var(--border);
  min-width: 25%;
}

.cell:first-of-type {
  padding-left: 0;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  padding-left: var(--v3);
}

.action {
  margin-left: var(--v4);
}

.chevron {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--secondary-100);
  margin-left: var(--v4);
  width: 30px;
  height: 30px;
  font-size: var(--p-font-size);
}

// More Actions
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  min-width: 180px;
  bottom: -6px;
  left: 50%;
  background: white;
  border-radius: 0 0 $border-radius $border-radius;
  transform: translate(-100%, 100%);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  overflow: hidden;
  z-index: 101;
}

.more-action {
  width: 100%;
  padding: var(--v2);
  cursor: pointer;
}

.mroe-action:hover {
  background: var(--secondary-50);
}

@media screen and (min-width: $bp-sm) {
  .container {
    padding: var(--v4);
  }

  .cell {
    padding: 0 var(--v3);
  }
}
