@use "src/shared/styles/variables" as *;

.cell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.cell p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--label-font-size);
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

.center {
  justify-content: center;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.action {
  margin-left: var(--v2);
  cursor: pointer;
  height: 100%;
}

.link {
  position: absolute;
  top: -30px;
  right: 0;
}

.table {
  flex: 1;
  min-height: 0;
}
