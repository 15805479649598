// Break Points
$bp-xxs: 375px; // Micro (XXS) - Small phones (iPhone SE, older Androids)
$bp-xs: 425px; // Small Phones (XS) - Standard mobile (iPhone 12 Mini, Galaxy S10e)
$bp-sm: 600px; // Large Phones (SM) - Big-screen phones (iPhone 15 Pro Max, Galaxy S24 Ultra)
$bp-smd: 768px; // Foldables (SMD) - Foldable devices (Samsung Galaxy Fold, Surface Duo)
$bp-md: 900px; // Tablets (MD) - iPad Mini, budget Android tablets
$bp-lgt: 1024px; // Large Tablets (LGT) - iPad Air/Pro, Surface Go
$bp-lg: 1280px; // Small Laptops (LG) - 13" MacBook Air, small Chromebooks
$bp-xl: 1440px; // Standard Laptops (XL) - Most work laptops, 1080p monitors
$bp-xxl: 1920px; // Large Desktops (XXL) - Ultra-wide screens, gaming rigs
$bp-xxxl: 2560px; // 4K Screens (XXXL) - High-res displays, TV monitors

//Styling
$border-radius: 10px;
$box-shadow: 0px 4px 15px 1px rgba(200, 200, 200, 0.25);
$box-shadow-orange: 0px 4px 15px 1px rgba(255, 99, 32, 0.25);
