.container {
  background: powderblue;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden; // Optional, if you don't want outer scrollbars
}

.grid {
  flex: 1;
  min-height: 0; // ✅ Necessary to prevent overflow when inside flex column
}
