@use "src/shared/styles/variables" as *;

.shiftly {
  width: 30%;
  min-width: 250px;
  height: 60px;
}

.third-party {
  border-radius: $border-radius;
  padding: 10px;
  width: 30%;
  min-width: 250px;
  border: 0.5px solid var(--secondary-400);
}

.create {
  margin-left: var(--v3);
  color: var(--orange);
  font-size: var(--h6-font-size);
  cursor: pointer;
}

.trash {
  margin-left: var(--v3);
  color: var(--secondary-800);
  font-size: var(--h6-font-size);
  cursor: pointer;
}
