@use "src/shared/styles/variables" as *;

.links-container {
  background: var(--primary-100);
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  padding: var(--v2);
}

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: var(--v1) var(--v2);
  border-radius: $border-radius;
  margin: var(--v1) 0;
}

.details {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-100);
  border-radius: 50%;
  margin-right: var(--v3);
  color: var(--orange);
  width: 40px;
  height: 40px;
}

.invoice-container {
  background: white;
  border-radius: $border-radius;
  padding: var(--v1) var(--v2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--v2);
}
