@use "src/shared/styles/variables" as *;

.title {
  text-align: center;
  margin: var(--v4) var(--v2) 0 var(--v2);
}

.subtitle {
  text-align: center;
  font-size: var(--label-font-size);
  width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: $bp-sm) {
  .subtitle {
    width: 60%;
  }
}
