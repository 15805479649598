@use "src/shared/styles/variables" as *;

.container {
  height: 100%;
  width: 100%;
  background: var(--secondary-100);
  display: flex;
  flex-direction: column;
  min-width: 1024px;
}

.calendar {
  padding: var(--v4) calc((var(--v6) + 30px));
  flex: 1;
  overflow: auto;
}

.new-position {
  width: 100%;
  background: white;
  padding: var(--v2);
  border-radius: 10px;
  margin-top: var(--v1);
}

.new-pos {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  background: var(--primary-50);
  padding: var(--v2);
}
