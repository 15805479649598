@use "src/shared/styles/variables" as *;

.xero-logo {
  width: 20px;
  height: 20px;
  margin-left: var(--v2);
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  right: var(--v2);
  transform: translateY(-50%);
}

.date-range {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-range p {
  font-weight: 500;
}

.header-button {
  height: 50px;
  padding: 0 var(--v2);
}

.action-button {
  padding: var(--v1) var(--v4);
  min-width: auto;
}

.status-pill {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
}

.status-pill p {
  margin-left: var(--v2);
  font-weight: 500;
  font-size: var(--label-font-size);
}

.status-pill.green svg {
  color: var(--success);
}

.status-pill.red svg {
  color: var(--error);
}

.disabled-row {
  background-color: var(--disabled-background);
}

.timesheet-modal {
  height: auto;
  width: 800px;
}
