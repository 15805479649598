.container {
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.no-messages-text {
  text-align: center;
  color: var(--secondary-400);
  margin-bottom: 40px;
  font-size: 24px;
}
