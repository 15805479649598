@use 'src/shared/styles/variables' as *;

.illustration {
  height: 40vh;
  width: 100%;
  margin: var(--v4) auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.illustration img {
  object-fit: contain;
}