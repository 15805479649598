@use "src/shared/styles/variables" as *;

.dates {
  position: relative;
  overflow: hidden;
  border-radius: $border-radius $border-radius 0 0;
}

.date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  height: 40px;
  position: relative;
  z-index: 2;
  width: 100%;
}

.prev,
.next {
  position: absolute;
  top: 0;
}

.prev {
  left: -100%;
}

.next {
  right: -100%;
}

.header-column {
  flex: 1;
}
.date-section {
  width: 12.5%;
  border-right: 1px solid var(--border);
  padding: 0 10px;
}

.date-section:last-child {
  border-right: none;
}

.control {
  font-size: var(--label-font-size);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  background: white;
  position: absolute;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
  margin: 0 -3px;
}

.control-left {
  left: 0;
  transform: translateX(-100%);
  border-radius: 8px 0 0 8px;
}

.control-right {
  right: 0;
  transform: translateX(100%);
  border-radius: 0 8px 8px 0;
}
