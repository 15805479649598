.container {
  background: white;
  width: 100%;
  padding: 20px;
  border-radius: 15px;
  box-shadow: var(--box-shadow);
  margin: 40px 0;
  min-height: 170px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.profiles {
  width: 100%;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  padding: 5px;
}

.profile-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  box-shadow: var(--box-shadow);
  cursor: pointer;
  padding: 10px;
  border-radius: 15px;
}

.profile-image {
  margin-right: 20px;
}

.initials-icon {
  background: var(--secondary-900);
  color: white;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.profile-image img {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  border: 2px solid var(--secondary-900);
}

.profile-info h4 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}

.profile-info p {
  font-size: 14px;
  color: var(--secondary-500);
}

.no-upcoming {
  text-align: center;
  width: 100%;
  margin-top: 20px;
  color: var(--secondary-300);
}
