@use "src/shared/styles/variables" as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 85vh;
  text-align: center;
}

.illustration {
  object-fit: contain;
  width: 240px;
  height: 192px;
}

@media screen and (min-width: $bp-xxl) {
  .illustration {
    object-fit: contain;
    width: 480px;
    height: 384px;
  }
}
