@use "src/shared/styles/variables" as *;

.container {
  flex: 1;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
}

.side-panel {
  height: 100%;
  box-shadow: $box-shadow;
  overflow: hidden;
}

.shift-row {
  padding: var(--v1);
  border-bottom: 1px solid var(--border);
  cursor: pointer;
}

.shift-row-wrapper {
  border-radius: $border-radius;
  padding: var(--v1);
}

.shift-row-wrapper:hover {
  background: var(--secondary-100);
}

.shift-position {
  font-size: var(--label-font-size);
  color: var(--secondary-600);
  margin-top: var(--v1);
}

.shift-position,
.shift-date {
  white-space: nowrap;
}

.active-row {
  background: var(--secondary-100);
}

/* Table */
.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.table-header {
  padding: 15px 20px;
  border-bottom: 1px solid var(--border);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table-header h3 {
  font-weight: 500;
  font-size: 18px;
}

.header-icon {
  border-radius: 10px;
  background: var(--secondary-100);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-900);
  margin-right: 20px;
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.sidepanel-icon.open {
  rotate: 0deg;
}

.sidepanel-icon {
  rotate: 180deg;
}

.table {
  flex: 1;
}

.status-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.status-icon.pending {
  background: var(--warning-500);
  border: 2px solid var(--secondary-900);
}

.status-icon.accepted {
  background: var(--success-50);
  border: 2px solid var(--success-500);
}

.status-icon.rejected {
  background: var(--error-50);
  border: 2px solid var(--error-500);
}

.shifter-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.shifter-profile {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: var(--v2);
  overflow: hidden;
}

.shifter-profile.pending {
  border: 2px solid var(--warning-500);
}

.shifter-profile.accepted {
  border: 2px solid var(--success-500);
}

.shifter-profile.rejected {
  border: 2px solid var(--error-500);
}

.shifter-cell p {
  font-weight: 600;
}

.shift-date {
  width: 100px;
}

.actions {
  padding-right: var(--v2);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accept {
  padding: 10px 40px;
}

.check-mark {
  margin-right: 50px;
  color: var(--success-500);
}

.declined-mark {
  margin-right: 50px;
  color: var(--error-500);
}

/* Payment Method */

.payment-method {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: auto;
}

.payment-method p {
  margin-right: 20px;
}
