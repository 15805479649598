.container {
  border-radius: 15px;
  box-shadow: var(--box-shadow);
  margin: 10px 0;
  background: white;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 25px;
  border-bottom: 1px solid var(--border);
}

.header-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-btn {
  border: 1px solid var(--border);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 7px;
  height: 40px;
  width: 40px;
  border-radius: 5px;
}

.header h5 span {
  font-size: 14px;
  color: var(--orange);
  margin-left: 15px;
}

.header h5 span svg {
  margin-left: 10px;
}

/* Body */

.body {
  padding: 10px;
}

.body-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}

.body-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.body-item p {
  font-size: 12px;
  font-weight: 700;
  margin-left: 15px;
}

.body-item svg {
  width: 20px;
}

/* Footer */
.footer {
  border-top: 1px solid var(--border);
}

.pin-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.pins {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pin-container p {
  font-size: 14px;
  font-weight: 600;
}

.pin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  color: white;
  border-radius: 7px;
  margin: 0 3px;
  background: var(--secondary-900);
  font-weight: 800;
}

.checked-in {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checked-in p {
  font-size: 14px;
  font-weight: 600;
}
