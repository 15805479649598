.table {
  width: 100%;
}

.table td {
  padding: var(--v1);
  border-top: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
}

.action {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--secondary-900);
  border-radius: 6px;
  cursor: pointer;
}

.rejected {
  margin-right: var(--v3);
}

.accepted {
  background: var(--secondary-900);
  color: white;
}

.favourite {
  color: var(--orange);
}

.card {
  margin: var(--v3) 0;
  border-top: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
  width: 100%;
}
