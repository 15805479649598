@use "src/shared/styles/variables" as *;
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 var(--v3);
  box-shadow: $box-shadow;
  background: white;
  z-index: 100;
}

.logo-container {
  min-width: 40px;
  width: 40px;
  margin-right: calc(var(--v3) + 60px);
  cursor: pointer;
}

.location-selector {
  min-width: 180px;
  transform: translateY(8px);
  transition: all 200ms ease;
}

.hidden {
  pointer-events: none;
  opacity: 0;
}

.navbar-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  padding: 0 var(--v2);
}

.account-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emergency-shift {
  margin: 0 0 0 20px;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}
