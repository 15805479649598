@use "src/shared/styles/variables" as *;

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.create-btn {
  position: absolute;
  top: 50%;
  right: var(--v2);
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
