.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px;
  margin-top: 10px;
}

.tablet-container {
  margin: 40px 0;
}

.input-container {
  flex: 1;
  margin: 0 20px;
  transform: translateY(8px);
}
