@use "src/shared/styles/variables" as *;

.account-link {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--v2) var(--v3);
  margin: var(--v1) 0;
  cursor: pointer;
}

.account-link:hover {
  background: var(--secondary-100);
}

.link-icon {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: var(--primary-100);
  color: var(--orange);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--v3);
  font-size: var(--p-font-size);
}
