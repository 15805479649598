@use "src/shared/styles/variables" as *;

.container {
  background: white;
  box-shadow: var(--box-shadow);
  width: 350px;
  height: 300px;
  border-radius: $border-radius;
  position: relative;
  padding: var(--v3);
  cursor: pointer;
  margin: var(--v2);
  margin-left: 0;
}

.container:hover {
  background: var(--secondary-50);
}

.no-hover:hover {
  background: white;
  cursor: default;
}
