@use "src/shared/styles/variables" as *;

.container {
  width: 100%;
  cursor: pointer;
  border-radius: $border-radius;
  border: 1px solid transparent;
  padding: var(--v2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}


