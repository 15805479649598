@use "src/shared/styles/variables" as *;

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: var(--secondary-200);
  height: var(--v6);
  width: 100%;
}

.tabs-wrapper {
  position: relative;
  height: 100%;
}

.left,
.right {
  display: flex;
  align-items: center;
  height: 100%;
}

.left {
  width: 100%;
  justify-content: flex-start;
}
.right {
  justify-content: flex-end;
  padding-right: var(--v3);
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  border-radius: $border-radius $border-radius 0 0;
  transform: translateY(5px);
  padding: 0 var(--v3);
  cursor: pointer;
  transition: background 0.3s;
  width: 180px;
  white-space: nowrap;
  font-size: var(--p-font-size);
  background: var(--secondary-100);
}

.tab.active {
  color: var(--orange);
  font-weight: 600;
  background: white;
}

.slider-container {
  width: 100%;
  height: 3px;
  position: relative;
  background: var(--secondary-100);
  transform: translateY(-3px);
}

.slider {
  position: absolute;
  top: 0;
  height: 100%;
  background: var(--orange);
  border-radius: $border-radius;
  transition: all 200ms ease;
}

.with-icon {
  transform: translateX(10px);
}

.action-button {
  padding: 0 var(--v2);
  height: var(--v4);
  font-size: var(--label-font-size);
  border: none;
  margin-left: var(--v3);
}

