@use "src/shared/styles/variables" as *;

.container {
  border: 2px solid var(--warning-500);
  border-radius: $border-radius;
  box-shadow: $box-shadow;
}

.header-action {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border);
  border-radius: $border-radius;
  width: 50px;
  height: 50px;
  margin-left: var(--v1);
}

.body {
  border-top: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  padding: var(--v1) var(--v2);
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: var(--v1) var(--v2);
}

.grid-icon {
  width: 20px;
  margin-right: var(--v1);
}

.grid-text {
  font-size: var(--label-font-size);
  white-space: nowrap;
}
