@use "src/shared/styles/variables" as *;
.action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--v2);
  height: 32px;
  background: white;
  border-radius: 6px;
  margin: 0 var(--v1);
  cursor: pointer;
  border: 2px solid transparent;
  position: relative;
}

.container {
  width: 285px;
  position: absolute;
  top: calc(100% + 10px);
  right: var(--v1);
  background: white;
  box-shadow: var(--box-shadow);
  border-radius: $border-radius;

  z-index: 101;
  overflow: hidden;
  opacity: 0;
  transition: all 0.2s ease;
  visibility: hidden;
}

.container.open {
  opacity: 1;
  visibility: visible;
}

.row {
  padding: var(--v2) var(--v1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  cursor: pointer;
}

.row p {
  font-size: var(--label-font-size);
  font-weight: 500;
}

.row:hover {
  background: var(--secondary-100);
}

.row-icon {
  color: var(--secondary-700);
  margin: 0 var(--v2);
}

.action-btn:hover {
  background: var(--secondary-100);
}

.action-btn p {
  font-size: var(--label-font-size);
  font-weight: 500;
  white-space: nowrap;
}

.divider-container {
  background: white;
}

.divider {
  height: 1px;
  background: var(--border);
}

.chevron {
  width: 12px;
}
