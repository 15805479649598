@use "src/shared/styles/variables" as *;

:root {
  /* Primary */
  --primary-50: #fff3ee;
  --primary-100: #ffdccd;
  --primary-200: #ffc5ac;
  --primary-300: #ffae8b;
  --primary-400: #ff9060;
  --primary-500: #fc763c;
  --primary-600: #ff8049;
  --primary-700: #ff6320;
  --primary-800: #ed4902;
  --primary-900: #df3600;
  --primary-1000: #d02904;
  --primary-1100: #bf2200;

  --orange-light: var(--primary-200);
  --orange: var(--primary-800);
  --orange-dark: var(--primary-1100);
  --orange-gradient: linear-gradient(90deg, #ed4902 0%, #df3600 52.83%, #d02904 100%);

  /* Secondary */
  --secondary-50: #f9faff;
  --secondary-100: #f2f7fd;
  --secondary-200: #e2e8f0;
  --secondary-300: rgb(206, 216, 224);
  --secondary-400: #94a3b9;
  --secondary-500: #7e8fa8;
  --secondary-600: #60728b;
  --secondary-700: #3f4f65;
  --secondary-800: #27384e;
  --secondary-900: #0f1a35;
  --secondary-1000: #040919;
  --secondary-1100: #00020a;

  --blue: var(--secondary-900);
  /* Success */
  --success-10: #f0f9f2;
  --success-50: #cbf3e1;
  --success-500: #5dcc99;
  --success-900: #145638;

  /* Warning */
  --warning-50: #fff4d8;
  --warning-300: #fdd56f;
  --warning-500: #fab606;

  /* Error */
  --error-50: #ffd0d0;
  --error-500: #e90008;
  --error-900: #7e0000;

  /* Shades */
  --shade-0: #ffffff;
  --shade-100: #040919;

  /* Greyscale */
  --greyscale-50: #f9f9f9;
  --greyscale-100: #f2f2f2;
  --greyscale-200: #d0d0d0;
  --greyscale-400: #9f9fa5;
  --greyscale-600: #707070;
  --greyscale-800: #3e3e40;

  /* Functional */
  --success-light: var(--success-50);
  --success-dark: var(--success-900);
  --success: var(--success-500);

  --warning-light: var(--warning-50);
  --warning-dark: var(--warning-500);
  --warning: var(--warning-300);

  --error-light: var(--error-50);
  --error-dark: var(--error-900);
  --error: var(--error-500);

  --disabled-text: var(--greyscale-400);
  --disabled-background: var(--greyscale-50);
  --placeholder-color: var(--secondary-300);

  --border: var(--secondary-100);
  --border-darker: var(--secondary-300);
  --input-background: white;
  --box-shadow: 0px 4px 15px 1px rgba(200, 200, 200, 0.25);
  --box-shadow-orange: 0px 4px 15px 1px rgba(255, 99, 32, 0.25);
}
