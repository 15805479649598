@use "src/shared/styles/variables" as *;

.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: white;
  border-radius: 0 $border-radius $border-radius 0;
  z-index: 110;
  padding: var(--v3);
  width: 300px;
}

.no-radius {
  border-radius: 0;
}

.logo {
  width: 100px;
}

.notification {
  background: var(--secondary-200);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: var(--secondary-900);
  border-radius: $border-radius;
}

/* Chevron */
.chevron {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(100%, -50%);
  padding: 25px 15px;
  background: var(--orange-gradient);
  color: white;
  border-radius: 0 $border-radius $border-radius 0;
  cursor: pointer;
}

/* Nav Item */

.icon {
  background: var(--secondary-200);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: var(--secondary-900);
  border-radius: $border-radius;
  margin-right: var(--v2);
}

.active .icon {
  background: var(--primary-200);
  color: var(--orange);
}

.active p {
  color: var(--orange);
}

.badge {
  width: 20px;
  height: 20px;
  background: var(--orange);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
}

/* Notifications */
.notification-centre {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  width: 450px;
  z-index: 110;
  height: 100vh;
  border-radius: 0 $border-radius $border-radius 0;
}

.notification-heading {
  padding: var(--v3);
}
